
import axios from "axios";
import { mapActions } from "vuex";

export default {
	data() {
		return {
			SrcPixelImg: "",
			baseURL:
				process.env.NUXT_ENV_API_BASE_URL ||
				"https://localhost:7283/api",
			isLoading: true,
		};
	},
	computed: {
		themes() {
			return this.site?.themes;
		},
		site() {
			return this.$store.state.site;
		},
	},
	async mounted() {
		await this.fetchExpiredContent();
		await this.encryptMarketerNumber();
		this.isLoading = false;
	},
	methods: {
		...mapActions("library", ["fetchExpiredContent"]),
		async encryptMarketerNumber() {
			const marketerNumber = this.site.user.ddcUserData?.marketerNo;
			if (marketerNumber) {
				try {
					const response = await axios.get(
						`${this.baseURL}/tooling/PixelGateway?marketerNumber=${marketerNumber}`
					);
					if (response.data) {
						this.pushGTMPixel(
							response.data.encryptedMarketerNumber
						);
						this.SrcPixelImg = response.data.urlPixel;
					}
				} catch (e) {
					return "";
				}
			}
		},
		pushGTMPixel(EncryptedMarketerNumber) {
			this.$gtm.push({
				marketer_number: EncryptedMarketerNumber,
			});
		},
	},
};
